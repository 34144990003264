function addProtocolToUrl(url) {
  const checkedUrl = url;

  if (!/^http(s)?:\/\//i.test(checkedUrl)) {
    return `https://${checkedUrl}`;
  }

  return checkedUrl;
}

function isValidURL(url) {
  // fyi: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
  const testUrl = addProtocolToUrl(url);
  // eslint-disable-next-line prettier/prettier
  const regEx =
    /(http(s)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

  return regEx.test(testUrl);
}

function getLinkForDisplay(url) {
  const urlObj = new URL(url);
  let link = `${urlObj.pathname}${urlObj.search}${urlObj.hash}`;
  if (link.length > 27) {
    link = `${link.substring(0, 24)}...`;
  }
  return link;
}

function isPathLoggedInArea(path) {
  if (path.indexOf('/account/') === 0) {
    return true;
  }
  return false;
}

function getHostnameFromUrl(url) {
  return new URL(url).hostname;
}

export { addProtocolToUrl, isValidURL, getLinkForDisplay, isPathLoggedInArea, getHostnameFromUrl };
